import { Text, HStack, Box, Spacer, Flex, keyframes, Link, Image } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { FC } from "react";
import { CustomIcon } from "src/atoms";

import { HEADER_HEIGHT_DESKTOP } from "../Header";
import useDropdownState from "../hook";
import { slide } from "../utils";

import Section from "./Section";

const opacityIn = keyframes({
  "0%": {
    opacity: 0,
  },
  "100%": {
    opacity: 1,
  },
});

const Dropdown: FC<{
  data: Gatsby.DatoCmsHeaderDropdown,
  menuIndex: number,
}> = ({ data, menuIndex }) => {
  const { ref, dispatch, isForceOpened, isSoftOpened } = useDropdownState({
    index: menuIndex,
  });

  const { title, sections, ctaIcon, ctaLabel, ctaLink } = data;

  return (
    <Box>
      <HStack
        alignItems="center"
        as="button"
        onMouseEnter={() => dispatch({ type: "SOFT_OPENED", payload: menuIndex })}
        onMouseLeave={() => dispatch({ type: "SCHEDULE_CLOSE" })}
        px="3"
        ref={ref.buttonRef}
      >
        <Text
          cursor="pointer"
          fontFamily="Mabry"
          fontSize="font-15"
          fontWeight="normal"
        >
          {title}
        </Text>

        <CustomIcon
          ml="1.5"
          name="chevronDown"
        />
      </HStack>

      <Box ref={ref.popoverRef}>
        <AnimatePresence>
          {(isForceOpened || isSoftOpened) ? (
            <motion.div
              animate="enter"
              exit="exit"
              initial="exit"
              style={{ display: "flex" }}
              transition={{
                duration: 0.25,
                easings: "easeOut",
              }}
              variants={slide}
            >
              <Spacer
                onClick={() => dispatch({ type: "FORCE_CLOSE" })}
                w={{ base: "0", md: "80px" }}
              />

              <Box
                borderBottomLeftRadius="lg"
                borderBottomRightRadius="lg"
                marginTop="-1px"
                maxH={`calc(100dvh - ${HEADER_HEIGHT_DESKTOP}px)`}
                onMouseEnter={() => dispatch({ type: "CANCEL_SCHEDULED_CLOSE" })}
                onMouseLeave={() => dispatch({ type: "SCHEDULE_CLOSE" })}
                overflowY="auto"
                transition="filter .2s ease-out"
                width="fit-content"
              >
                <Flex
                  bg="white"
                  borderBottomRadius={ctaLabel ? 'none' : '2xl'}
                  overflow="hidden"
                >
                  {sections?.map((section: Gatsby.DatoCmsHeaderSection, index: number) => {
                    if (!section) {
                      return null;
                    }

                    return (
                      <Section
                        data={section}
                        index={index}
                        key={index}
                        totalSections={sections.length}
                      />
                    );
                  })}
                </Flex>

                {ctaLabel ? (
                  <Link
                    _hover={{
                      ".arrow-icon": {
                        transform: (theme) => `scale(1.2) translateX(${theme.space[1]})`,
                      },
                    }}
                    to={ctaLink}
                  >
                    <HStack
                      bg="grey.250"
                      borderBottomRadius="2xl"
                      cursor="pointer"
                      px="10"
                      py="7"
                      spacing="4"
                    >
                      {ctaIcon?.url ? (
                        <Image
                          alt={ctaIcon?.alt || ""}
                          height="18px"
                          src={ctaIcon?.url}
                          width="18px"
                        />
                      ) : null}
                      
                      <Text
                        flex="1"
                        fontSize="font-15"
                        fontWeight="medium"
                        lineHeight="short"
                      >
                        {ctaLabel}
                      </Text>

                      <CustomIcon
                        className="arrow-icon"
                        name="arrowRight"
                        transform="scale(1.2)"
                        transition="transform .2s ease-in-out"
                      />
                    </HStack>
                  </Link>
                ) : null}
              </Box>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Box>

      <Box
        animation={`${opacityIn} .2s forwards ease-out`}
        as={Box}
        bg="linear-gradient(180deg, rgb(37, 36, 29, 0), rgb(37, 36, 29, .7))"
        h={`calc(100dvh - ${HEADER_HEIGHT_DESKTOP}px)`}
        left="0"
        opacity="0"
        position="fixed"
        top={`${HEADER_HEIGHT_DESKTOP}px`}
        visibility={(isForceOpened || isSoftOpened) ? "visible" : "hidden"}
        width="100vw"
        zIndex="-1"
      />
    </Box>
  )
};

export default Dropdown;