import { Box, Heading, HStack } from "@chakra-ui/react";
import { FC } from "react";
import { variableTransformer } from "src/services/metadata/variable";

import StructuredText from "../../StructuredText";
import SocialShare from "../SocialShare";

import SubHeader from "./SubHeader";

const Intro: FC<Gatsby.ArticleDataFragment | Gatsby.GuideDataFragment> = ({
  author,
  reviewer,
  title,
  intro,
  body,
  meta,
  date,
}) => {
  return (
    <Box mb="10">
      <Heading
        as="h1"
        fontSize={{ base: "font-32", md: "font-40" }}
        lineHeight="shortest"
      >
        {variableTransformer(title!)}
      </Heading>

      <SubHeader
        author={author}
        body={body}
        date={date}
        meta={meta}
        reviewer={reviewer}
      />

      <HStack
        display={{ base: 'flex', xl: 'none' }}
        spacing="2"
        >
        <SocialShare tooltipPlacement="top" />
      </HStack>

      {intro && intro.value !== null && (
        <Box
          borderLeft="2px"
          borderLeftColor="orange.500"
          my={14}
          paddingLeft="6"
        >
          <StructuredText data={intro} />
        </Box>
      )}
    </Box>
  );
};

export default Intro;
