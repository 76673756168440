import { useOutsideClick, usePopper } from "@chakra-ui/react";
import { mergeRefs } from "@chakra-ui/react-utils";
import { useContext, useRef } from "react";
import { MenuContext } from "src/components/Layout/Header/state/context";

const useDropdownState = ({ index }: { index: number }) => {
  const popoverRef = useRef(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { state, dispatch } = useContext(MenuContext);

  const { referenceRef, popperRef } = usePopper({
    placement: "bottom",
    offset: [0, 28],
  });

  useOutsideClick({
    ref: popoverRef,
    handler: (event) => {
      if (
        state.forceOpened === index &&
        !buttonRef.current?.contains(event.target as HTMLElement)
      ) {
        dispatch({ type: "FORCE_CLOSE" });
      }
    },
  });

  const isForceOpened = state.forceOpened === index;
  const isSoftOpened = state.opened === index && state.forceOpened === null;

  return {
    ref: {
      buttonRef: mergeRefs(
        (elem) => referenceRef<HTMLButtonElement>(elem),
        buttonRef
      ),
      popoverRef: mergeRefs(popperRef, popoverRef),
    },
    dispatch,
    isForceOpened,
    isSoftOpened,
  };
};

export default useDropdownState;
