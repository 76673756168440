import { Link } from "@chakra-ui/react";
import { defaultMetaTransformer as metaTransformer } from "datocms-structured-text-generic-html-renderer";
import { renderRule, isLink } from "datocms-structured-text-utils";
import { ReactElement } from "react";
import { CustomIcon, InternalLink } from "src/atoms";
import { isInternalLink } from "src/atoms/InternalLink/utils";

type LinkProps = {
  key: string;
  children: ReactElement | null[];
  target?: string;
  href: string;
};

const StyledLink = ({ key, children, target, href }: LinkProps) => {
  const isInternal = isInternalLink(href);
  
  if (href.startsWith("/")) {
    return (
      <Link
        _hover={{ textDecor: "underline" }}
        as={InternalLink}
        color="text.cta"
        isExternal={false}
        key={key}
        textDecoration="underline"
        to={href}
      >
        {children}
      </Link>
    )
  }

  return (
    <Link
      _hover={{ textDecor: "underline" }}
      color={isInternal ? "text.cta" : "grey.800"}
      href={href}
      isExternal={target === "_blank" ? true : !isInternal}
      key={key}
      textDecoration="underline"
    >
      {children}

      {!isInternal ? <CustomIcon ml="1" name="link" verticalAlign="middle" /> : null}
    </Link>
  );
};

const link = renderRule(
  isLink,
  ({ adapter: { renderNode }, key, children, node }) => {
    const meta = node.meta ? metaTransformer({ node, meta: node.meta }) : {};

    return renderNode(
      StyledLink,
      { ...(meta || {}), key, href: node.url },
      children
    );
  }
);

export default link;
