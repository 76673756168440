import { Text, HStack, Link, Image } from "@chakra-ui/react";
import { FC } from "react";

const SectionButton: FC<Gatsby.DatoCmsHeaderSectionButton> = ({ icon, label, link }) => {
  return (
    <Link
      _hover={{
        opacity: 1,
      }}
      href={link}
      key={link}
      opacity={0.8}
    >
      <HStack
        spacing="4"
      >
        {icon?.url ? (
          <Image
            alt={icon?.alt || ""}
            className="btn-icon"
            height="18px"
            src={icon?.url}
            width="18px"
          />
        ) : null}

        <Text
          className="btn-text"
          color="grey.800"
          fontFamily="Mabry"
          fontSize="font-15"
          lineHeight="none"
        >
          {label}
        </Text>
      </HStack>
    </Link>
  );
};

export default SectionButton;
