const linkResolver = (link: string): string => {
  let resolvedLink = link;

  if (resolvedLink && !resolvedLink.startsWith("/"))
    resolvedLink = `/${resolvedLink}`;

  if (resolvedLink && !resolvedLink.includes('#') && !resolvedLink.endsWith("/"))
    resolvedLink = `${resolvedLink}/`;

  return resolvedLink;
};

const isInternalLink = (link: string): boolean => {
  if (!link) {
    return false;
  }

  if (link.startsWith("/")) {
    return true;
  }

  if (link.includes("https://shine.fr") || link.includes("https://www.shine.fr")) {
    return true;
  }

  return false;
};

export { linkResolver, isInternalLink };
