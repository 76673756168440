import { HStack, Flex, Image, chakra, Text, VStack, Box } from "@chakra-ui/react";
import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { CustomIcon, InternalLink } from "src/atoms";
import { getBeenUpdated, getFormattedDates } from "src/services/article/date";
import { getReadTime } from "src/services/article/readTime";
import { getAuthorLink } from "src/services/author/link";
import { getReviewerLink } from "src/services/reviewer/link";

const Desktop: FC<Pick<Gatsby.ArticleDataFragment, "date" | "meta" | "author" | "reviewer" | "body">> = ({
  date,
  meta,
  author,
  reviewer,
  body,
}) => {
  const { firstPublishedAt, updatedAt } = getFormattedDates(date, meta);
  const beenUpdated = getBeenUpdated(date, meta);
  
  const readTime = getReadTime(toPlainText(body) || "");

  return (
    <Box
      display={{ base: "none", md: "block" }}
      mb={{ base: "6", xl: 0 }}
    >
      <Flex
        alignItems="center"
        flexWrap="wrap"
        gridGap="60px"
        justifyContent="flex-start"
        my="10"
      >
        {author ? (
          <HStack
            alignItems="center"
            spacing="4"
          >
            {author.image && (
              <Image
                alt={author.image.alt || ""}
                as={GatsbyImage}
                height="9"
                image={author.image.gatsbyImageData}
                imgStyle={{
                  overflow: "hidden",
                  borderRadius: "50%",
                }}
                overflow="hidden"
                width="9"
              />
            )}

            <VStack
              alignItems="flex-start"
              spacing="2"
            >
              <Text
                color="grey.800"
                fontFamily="Mabry"
                fontSize="font-14"
                lineHeight="1"
              >
                Publié le {firstPublishedAt}
              </Text>

              <Text
                color="grey.800"
                fontFamily="Mabry"
                fontSize="font-14"
                lineHeight="1"
              >
                <chakra.span>
                  par{' '}
                </chakra.span>

                <InternalLink
                  style={{ textDecoration: "underline" }}
                  to={getAuthorLink(author.slug!)}
                >
                  {author.name}
                </InternalLink>
              </Text>
            </VStack>

          </HStack>
        ) : null}

        {reviewer && beenUpdated && meta ? (
          <HStack
            alignItems="center"
            spacing="4"
          >
            {reviewer.image && (
              <Image
                alt={reviewer.image.alt || ""}
                as={GatsbyImage}
                height="9"
                image={reviewer.image.gatsbyImageData}
                imgStyle={{
                  overflow: "hidden",
                  borderRadius: "50%",
                }}
                overflow="hidden"
                width="9"
              />
            )}

            <VStack
              alignItems="flex-start"
              spacing="2"
            >
              <Text
                color="grey.800"
                fontFamily="Mabry"
                fontSize="font-14"
                lineHeight="1"
              >
                Mis à jour le {updatedAt}
              </Text>

              <Text
                color="grey.800"
                fontFamily="Mabry"
                fontSize="font-14"
                lineHeight="1"
              >
                <chakra.span>
                  par{' '}
                </chakra.span>

                <InternalLink
                  style={{ textDecoration: "underline" }}
                  to={getReviewerLink(reviewer.slug!)}
                >
                  {reviewer.name}
                </InternalLink>
              </Text>
            </VStack>

          </HStack>
        ) : null}
      </Flex>

      <HStack
        alignItems="center"
        padding="2"
        spacing="2"
      >
        <CustomIcon name="clock" />

        <Text
          color="grey.700"
          fontFamily="Mabry"
          fontSize="font-14"
          lineHeight="1"
        >
          {readTime}
        </Text>
      </HStack>
    </Box>
  );
}

export default Desktop;
