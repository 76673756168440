import { Box, Heading, Grid } from "@chakra-ui/react";
import { FC } from "react";
import Carousel from "src/atoms/Carousel";

import SectionAdvertisement from "./SectionAdvertisement";
import SectionButton from "./SectionButton";

const Section: FC<{
  data: Gatsby.DatoCmsHeaderSection,
  index: number,
  totalSections: number,
}> = ({ data, index, totalSections }) => {
  const { title, whiteBackground, columns, buttons, advertisements } = data;

  return (
    <Box
      bg={whiteBackground ? "white" : "grey.250"}
      ml={!whiteBackground ? "10" : "0"}
      p="10"
      pl={(index === 0 || !whiteBackground) ? "10" : "18"}
      pr={index === totalSections - 1 ? "10" : "0"}
    >
      {title ? (
        <Box mb={{ base: "4", lg: "10" }}>
          <Heading
            color="grey.800"
            fontFamily="Mabry"
            fontSize="font-18"
            lineHeight="1"
            mb="10"
            whiteSpace="nowrap"
          >
            {title}
          </Heading>
        </Box>
      ) : null}

      {buttons?.length ? (
        <Grid
          columnGap={columns ? { md: "6", lg: "18" } : "0"}
          gridTemplateColumns={columns ? `repeat(${columns}, 1fr)` : "none"}
          rowGap={columns ? "6" : "0"}
        >
          {buttons.map((button: Gatsby.DatoCmsHeaderSectionButton, index: number) => {
            if (!button) {
              return null;
            }

            return (
              <SectionButton
                key={button.link}
                {...button}
              />
            );
          })}
        </Grid>
      ) : null}

      {advertisements?.length === 1 ? (
        <SectionAdvertisement {...advertisements[0]} />
      ) : null}

      {(advertisements?.length || 0) > 1 ? (
        <Carousel
          breakPointValue={{ base: 1, md: 1, lg: 1 }}
          carouselWrapperProps={{
            paddingTop: "0",
            spacing: "0",
            templateColumns: {
              base: `repeat(1, auto)`,
              md: `repeat(1, auto)`,
              lg: `repeat(1, auto)`,
            }
          }}
          indicators="dots"
          items={advertisements.filter(Boolean).map((ad: Gatsby.DatoCmsHeaderSectionAdvertisement) => (
            <SectionAdvertisement
              key={ad.title}
              {...ad}
            />
          ))}
        />
      ) : null}
    </Box>
  );
}

export default Section;
