import { MenuAction, MenuState } from ".";

export const initialState: MenuState = {
  opened: null,
  forceOpened: null,
  scheduledClose: null,
};

const reducer = (state: MenuState, action: MenuAction) => {
  switch (action.type) {
    case "FORCE_OPENED":
      return { ...state, opened: null, forceOpened: action.payload };

    case "SOFT_OPENED":
      return { ...state, opened: action.payload };

    case "SET_CLOSE_TIMER":
      return {
        ...state,
        scheduledClose: action.payload,
      };

    case "CANCEL_SCHEDULED_CLOSE":
      if (state.scheduledClose) clearTimeout(state.scheduledClose);

      return { ...state, scheduledClose: null };

    case "CLOSE":
      return { ...state, opened: null };

    case "FORCE_CLOSE":
      return { ...state, opened: null, forceOpened: null };

    default:
      return state;
  }
};

export default reducer;
