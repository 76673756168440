import { isSameYear } from 'date-fns';

const addYear = (dateFormatted: string, dateStr: string) => {
  const date = new Date(dateStr);

  return dateFormatted + (isSameYear(date, new Date()) ? '' : ' ' + date.getFullYear());
};

const getFormattedDates = (date: Date, meta?: Pick<Gatsby.ArticleDataFragment, 'meta'>) => {
  if (!date) {
    throw new Error('Date is required');
  }

  const firstPublishedAt = meta?.formattedFirstPublishedAt && meta?.firstPublishedAt
    ? addYear(meta?.formattedFirstPublishedAt, meta?.firstPublishedAt)
    : date.toLocaleString('fr-FR', {
      day: 'numeric',
      month: 'short',
      year: isSameYear(date, new Date()) ? undefined : 'numeric',
    });

  const beenUpdated = getBeenUpdated(date, meta);

  return {
    firstPublishedAt,
    updatedAt: beenUpdated ? addYear(meta?.formattedUpdatedAt, meta?.updatedAt) : null,
  }
}

const getBeenUpdated = (date: Date, meta?: Pick<Gatsby.ArticleDataFragment, 'meta'>) => {
  if (!date) {
    throw new Error('Date is required');
  }

  const publishedDate = new Date(meta?.firstPublishedAt || date!);
  const updatedDate = meta?.updatedAt
    ? new Date(meta?.updatedAt)
    : publishedDate;

  return updatedDate > publishedDate && updatedDate.toDateString() !== publishedDate.toDateString();
}

const getLatestFormattedDate = (date: Date, meta?: Pick<Gatsby.ArticleDataFragment, 'meta'>) => {
  const beenUpdated = getBeenUpdated(date, meta);

  return beenUpdated && meta?.formattedUpdatedAt
    ? addYear(meta.formattedUpdatedAt, meta.updatedAt)
    : meta?.formattedFirstPublishedAt
      ? addYear(meta.formattedFirstPublishedAt, meta.firstPublishedAt)
      : date.toLocaleString('fr-FR', {
          day: 'numeric',
          month: 'short',
          year: isSameYear(date, new Date()) ? undefined : 'numeric',
      });
}

export {
  getFormattedDates,
  getBeenUpdated,
  getLatestFormattedDate,
}
