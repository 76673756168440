import { Text, Box, HStack, Image, Flex, Link, Button } from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import { FC, useState, Fragment } from "react";
import { CustomIcon, InternalLink, SearchInput } from "src/atoms";

import Dropdown from "./components/Dropdown";
import MobileDrawer from "./components/MobileDrawer";
import MobileSearch from "./components/MobileSearch";
import useHeaderDropdownState from "./state";
import { MenuContext } from "./state/context";

export const HEADER_HEIGHT_MOBILE = 62
export const HEADER_HEIGHT_DESKTOP = 73

const Header = () => {
  const { header } = useStaticQuery<Gatsby.HeaderQuery>(
    graphql`
      query Header {
        header: datoCmsHeader {
          ...DatoCmsHeader
        }
      }
    `
  );

  if (!header) {
    return null;
  }

  const { logo, navigation, companyLabel } = header

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const { state, dispatch } = useHeaderDropdownState();

  const icons = [
    {
      display: { base: "block", xl: "none" },
      icon: <CustomIcon cursor="pointer" name="magnifier"/>,
      id: "magnifier",
      onClick: () => setSearchOpen(true),
    },
    {
      display: { base: "block", lg: "none" },
      icon: <CustomIcon cursor="pointer" name="burger" />,
      id: "burger",
      onClick: () => setMobileMenuOpen(true),
    },
  ];

  return (
    <Fragment>
      <Box
        maxW="100vw"
        pointerEvents="none"
        position="fixed"
        top="0"
        w="full"
        zIndex="10"
      >
        <Box
          background="white"
          pointerEvents="auto"
        >
          <Box
            as="header"
            margin="auto"
            maxW={"container.max"}
            padding={{
              base: "8px 4px 8px 16px",
              lg: "12px 24px",
            }}
            w="100%"
          >
            <Flex alignItems="center" justifyContent="space-between">
              <HStack
                alignItems="flex-start"
                flexShrink="0"
              >
                <Link as={InternalLink} to={"/"}>
                  <Image
                    alt={logo?.alt || ""}
                    maxH={{ base: "22px", md: "24px" }}
                    src={logo?.url}
                  />
                </Link>

                <Text
                  color="grey.800"
                  fontFamily="Value Serif Pro"
                  fontSize="font-20"
                  lineHeight="none"
                >
                  Blog
                </Text>
              </HStack>

              <MenuContext.Provider value={{ state, dispatch }}>
                <HStack
                  display={{ base: "none", lg: "flex" }}
                  flexGrow="1"
                  flexWrap="wrap"
                  ml="7"
                >
                  {navigation?.map((dropdown, index) => {
                    if (!dropdown) {
                      return null;
                    }

                    if (!dropdown.sections?.length) {
                      return (
                        <Link
                          as={dropdown.ctaLink?.startsWith("http") ? "a" : InternalLink}
                          fontFamily="Mabry"
                          fontSize="font-15"
                          fontWeight="normal"
                          href={dropdown.ctaLink}
                          key={dropdown.title}
                          px="3"
                        >
                          {dropdown.title}
                        </Link>
                      )
                    }

                    return (
                      <Dropdown
                        data={dropdown}
                        key={dropdown.title}
                        menuIndex={index}
                      />
                    )
                  })}
                </HStack>
              </MenuContext.Provider>

              <Flex
                alignItems="center"
                alignSelf={{ md: "flex-end" }}
                flexShrink="0"
                justifyContent={{ base: "space-between", md: "flex-end" }}
              >
                <SearchInput
                  buttonLeft
                  display={{ base: "none", xl: "block" }}
                  maxW="194px"
                  mx="5"
                  placeholder="Rechercher..."
                />

                <Button
                  as={Link}
                  display={{ base: "none", md: "block" }}
                  href={process.env.GATSBY_SHINE_URL}
                  isExternal
                  variant="yellow" 
                >
                  {companyLabel}

                  <CustomIcon
                    ml="2"
                    name="arrowRight"
                    transform="scale(1.2)"
                  />
                </Button>

                <Flex
                  alignItems="center"
                  alignSelf={{ md: "flex-end" }}
                  justifyContent="flex-end"
                >
                  {icons.map(({ icon, onClick, id, display }) => (
                    <Box
                      _active={{ bg: "transparent" }}
                      _hover={{ bg: "transparent" }}
                      alignItems="center"
                      as="button"
                      bg="transparent"
                      display={display}
                      key={id}
                      onClick={onClick}
                      padding="3"
                    >
                      {icon}
                    </Box>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>

      <MobileDrawer
        header={header}
        isOpen={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      />

      <MobileSearch isOpen={searchOpen} onClose={() => setSearchOpen(false)} />
    </Fragment>
  );
};

export default Header;
