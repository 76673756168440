import {
  Box,
  SimpleGrid,
  useBreakpointValue,
  HStack,
  BoxProps,
  SimpleGridProps
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { cloneElement, FC, useState } from "react";

import CarouselArrow from "./components/CarouselArrow";
import { motionVariantsDynamic } from "./utils";

const Carousel: FC<
  BoxProps & {
    items: any[];
    breakPointValue?: { base: number; md: number; lg: number };
    lightColor?: boolean,
    carouselWrapperProps?: SimpleGridProps
    indicators?: 'arrows' | 'dots'
  }
> = ({
  items,
  breakPointValue,
  lightColor,
  carouselWrapperProps,
  indicators = 'arrows',
}) => {
  const [[carouselIndex, direction], setCarouselIndex] = useState([0, 0]);

  const carouselBreakpointValue = breakPointValue ?? { base: 1, md: 2, lg: 3 };

  const numberOfItems = useBreakpointValue(carouselBreakpointValue) ?? 1;
  const pagesNumber = Math.ceil(items.length / numberOfItems);
  const shouldShowArrows = Boolean(numberOfItems && pagesNumber > 1);

  return (
    <Box>
      <AnimatePresence custom={direction} exitBeforeEnter initial={false}>
        <motion.div
          animate="in"
          custom={direction}
          exit="after"
          initial="before"
          key={carouselIndex}
          transition={{ ease: "easeOut", duration: 0.2 }}
          variants={motionVariantsDynamic('10px')}
        >
          <SimpleGrid
            paddingTop="16"
            spacing="8"
            templateColumns={{
              base: `repeat(${carouselBreakpointValue.base}, 1fr)`,
              md: `repeat(${carouselBreakpointValue.md}, 1fr)`,
              lg: `repeat(${carouselBreakpointValue.lg}, 1fr)`,
            }}
            {...carouselWrapperProps}
          >
            {Array(carouselBreakpointValue.lg)
              .fill("")
              .map((_, i) => {
                const item = items[carouselIndex + i];

                return (
                  item &&
                  cloneElement(item, {
                    display: {
                      base:
                        i <= carouselBreakpointValue.base - 1
                          ? "block"
                          : "none",
                      md:
                        i <= carouselBreakpointValue.md - 1 ? "block" : "none",
                      lg:
                        i <= carouselBreakpointValue.lg - 1 ? "block" : "none",
                    },
                  })
                );
              })}
          </SimpleGrid>
        </motion.div>
      </AnimatePresence>

      {indicators === 'arrows' && shouldShowArrows ? ( 
        <HStack justify="center" mt="10" spacing="4">
          {
            <CarouselArrow
              aria-label="prev"
              disabled={carouselIndex <= 0}
              light={lightColor}
              onClick={() =>
                setCarouselIndex([
                  Math.max(carouselIndex - numberOfItems, 0),
                  -1,
                ])
              }
              transform="rotate(180deg)"
            />
          }

          {
            <CarouselArrow
              aria-label="next"
              disabled={carouselIndex / numberOfItems >= pagesNumber - 1}
              light={lightColor}
              onClick={() =>
                setCarouselIndex([carouselIndex + numberOfItems, 1])
              }
            />
          }
        </HStack>
      ) : null}

      {indicators === 'dots' ? (
        <HStack mt="4" spacing="2">
          {Array(pagesNumber).fill("").map((_, i) => {
            const isActive = carouselIndex === i;
  
            return (
              <Box
                bg={isActive ? "black" : "grey.250"}
                borderRadius="6.5px"
                cursor="pointer"
                h="6.5px"
                key={i}
                onClick={() => setCarouselIndex([i, i > carouselIndex ? 1 : -1])}
                sx={{
                  transition: "width .2s ease",
                }}
                w={isActive ? "13px" : "6.5px"}
              />
            );
          })}
        </HStack>
      ) : null}
    </Box>
  );
};

export default Carousel;
