function getReviewerLink(slug: string, page?: number): string {
  const link = `/auteur/${slug}/`;

  if (!page || page <= 1) {
    return link;
  }

  return `${link}${page}/`;
}

export { getReviewerLink };
