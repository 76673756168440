import { Link } from "@chakra-ui/react";
import { Record } from "datocms-structured-text-utils";
import { StructuredTextPropTypes } from "react-datocms";
import { InternalLink } from "src/atoms";

const renderLinkToRecord: StructuredTextPropTypes<Record>["renderLinkToRecord"] =
  (link) => (
    <Link
      _hover={{ textDecor: "underline" }}
      as={InternalLink}
      color="text.cta"
      isExternal={link.transformedMeta?.target === "_blank"} //to do
      textDecoration="underline"
      to={`/${link.record.slug}`}
    >
      {link.children}
    </Link>
  );

export default renderLinkToRecord;
