import { FC } from "react";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

const SubHeader: FC<Pick<Gatsby.ArticleDataFragment, "date" | "meta" | "author" | "reviewer" | "body">> = ({
  author,
  body,
  date,
  meta,
  reviewer,
}) => {
  return (
    <>
      <Desktop
        author={author}
        body={body}
        date={date}
        meta={meta}
        reviewer={reviewer}
      />

      <Mobile
        author={author}
        body={body}
        date={date}
        meta={meta}
        reviewer={reviewer}
      />
    </>
  );
};

export default SubHeader;
