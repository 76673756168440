import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  UseDisclosureReturn,
  Accordion,
  Link,
  Image,
  Spacer,
  VStack,
  HStack,
  Button,
} from "@chakra-ui/react";
import { FC } from "react";
import { CustomIcon, InternalLink } from "src/atoms";

import MobileAccordionItem from "./MobileAccordionItem";

type MobileDrawerProps = Pick<UseDisclosureReturn, "isOpen" | "onClose">;

const MobileDrawer: FC<MobileDrawerProps & { header: Gatsby.DatoCmsHeaderFragment }> =
  ({ header, isOpen, onClose }) => {
    const { logo, navigation, companyLabel } = header;

    return (
      <Drawer isOpen={isOpen} onClose={onClose} placement="top">
        <DrawerOverlay bg="grey.600" />

        <DrawerContent
          borderBottomRadius="2xl"
          pb={8}
        >
          <HStack
            alignItems="center"
            px="4"
            py="3"
          >
            {logo?.url ? (
              <Image
                alt={logo?.alt || ""}
                maxH="18px"
                src={logo?.url}
              />
            ) : null}

            <Spacer flex="1" />

            <DrawerCloseButton>
              <CustomIcon h="12px" name="closeNav" w="12px" />
            </DrawerCloseButton>
          </HStack>


          <DrawerBody p="0">
            <VStack h="full" spacing="0">

              <Accordion
                allowToggle
                py="8"
                textAlign="left"
                width="100%"
              >
                {navigation?.map((dropdown) => {
                  if (!dropdown) {
                    return null;
                  }

                  if (!dropdown.sections?.length) {
                    return (
                      <Link
                        as={dropdown.ctaLink?.startsWith("http") ? "a" : InternalLink}
                        display="block"
                        fontFamily="Mabry"
                        fontSize="font-15"
                        fontWeight="medium"
                        href={dropdown.ctaLink}
                        key={dropdown.title}
                        lineHeight="none"
                        px="4"
                        py="5"
                      >
                        {dropdown.title}
                      </Link>
                    )
                  }

                  return (
                    <MobileAccordionItem
                      data={dropdown}
                      key={dropdown.title}
                    />
                  )
                })}
              </Accordion>
              
              <Button
                as={Link}
                href={process.env.GATSBY_SHINE_URL}
                isExternal
                variant="shinePrimary"
              >
                {companyLabel}

                <CustomIcon
                  ml="2"
                  name="arrowRight"
                  sx={{
                    path: {
                      fill: "white",
                    }
                  }}
                  transform="scale(1.2)"
                />
              </Button>

            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  };

export default MobileDrawer;
