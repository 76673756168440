import { VStack, HStack, Text, Image, Link } from "@chakra-ui/react";
import { FC } from "react";
import { CustomIcon } from "src/atoms";

export const ADVERTISEMENT_WIDTH = "340px";  
export const ADVERTISEMENT_HEIGHT = "85px"; 

const SectionAdvertisement: FC<Gatsby.DatoCmsHeaderSectionAdvertisement> = ({
  backgroundColor,
  image,
  imagePositionTop,
  imageMargin,
  title,
  link,
}) => {
  return (
    <Link
      _hover={{
        ".arrow-icon": {
          transform: (theme) => `scale(1.2) translateX(${theme.space[1]})`,
        },
      }}
      to={link}
    >
      <VStack
        bg={backgroundColor?.hex || "grey.250"}
        borderRadius="lg"
        overflow="hidden"
        pt={imagePositionTop && imageMargin ? "8" : "0"}
        spacing="0"
        w={ADVERTISEMENT_WIDTH}
      >
          {image?.url && imagePositionTop ? (
            <Image
              alt={image?.alt || ""}
              maxH={ADVERTISEMENT_HEIGHT}
              maxW="full"
              src={image?.url}
            />
          ) : null}

        <HStack
          alignItems="center"
          h={ADVERTISEMENT_HEIGHT}
          justifyContent="flex-end"
          pl={((!imagePositionTop && imageMargin) || imagePositionTop) ? "8" : "0"}
          pr="8"
          spacing="4"
          w="full"
        >
          {image?.url && !imagePositionTop ? (
            <Image
              alt={image?.alt || ""}
              maxH={ADVERTISEMENT_HEIGHT}
              maxW="160px"
              src={image?.url}
            />
          ) : null}

          <Text
            color="grey.800"
            flex="1"
            fontFamily="Mabry"
            fontSize="font-15"
            lineHeight="short"
          >
            {title}
          </Text>

          <CustomIcon
            className="arrow-icon"
            name="arrowRight"
            transform="scale(1.2)"
            transition="transform .2s ease-in-out"
          />
        </HStack>
      </VStack>
    </Link>
  );
};

export default SectionAdvertisement;
