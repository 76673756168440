import { ButtonProps, Button } from "@chakra-ui/react";
import { FC } from "react";

const CarouselArrow: FC<ButtonProps & { light?: boolean }> = ({
  light,
  ...props
}) => (
  <Button
    _disabled={{
      bg: "grey.250",
      color: "grey.800",
      opacity: "0.6",
      cursor: "default",
    }}
    _hover={
      props.disabled
        ? {}
        : {
            bg: light ? "grey.800" : "grey.700",
            color: "white"
          }
    }
    bg={light ? "grey.250" : "grey.800"}
    borderRadius="full"
    color={light ? "grey.800" : "white"}
    paddingY="11px"
    {...props}
  >
    →
  </Button>
);

export default CarouselArrow;
